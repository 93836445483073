<template>
  <v-navigation-drawer app dark class="primary"
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-icon dark>mdi-account-circle</v-icon>
      </v-list-item-avatar>

      <v-list-item-title>{{ user }}</v-list-item-title>

      <v-btn
        icon
        @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item router :to="item.to" v-bind:style=" item.title=='Salir' ? 'position: absolute; bottom: 0px; width: 100%' : '' "
        v-for="item in items"
        :key="item.title"
        link
      >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
  const axios = require("axios");
  import {BASE_URL} from '../components/constants.js'
  export default {
    data () {
      return {
        drawer: false,
        user: '',
        items: [
          //{ title: 'Inicio', icon: 'mdi-home-city', to: 'home'},
          { title: 'Obras', icon: 'mdi-domain', to: 'obras'},
          { title: 'Maquinaria', icon: 'mdi-tools', to: 'maquinaria'},
          { title: 'Documentos', icon: 'mdi-text-box-search', to: 'tiposdocumento'},
          //{ title: 'Informes', icon: 'mdi-text-box-multiple', to: 'informes'},
          { title: 'Usuarios', icon: 'mdi-account-group-outline', to: 'usuarios'},
          { title: 'Exporta', icon: 'mdi-cloud-download', to: 'exporta'},
          { title: 'Salir', icon: 'mdi-logout', to: '/'},
        ],
        mini: true,
      }
    },
    beforeMount() {
      this.getUser();
    },
    methods: {
      getUser(){
        var id = this.$store.state.user;
        var axiosConfig = {
          headers: {
            'Authorization': 'Bearer ' + this.$store.state.auth
          }
        };

        axios.get(BASE_URL+'/api/user/'+id, axiosConfig)
        .then((res) => {
          switch(res.status){
            case 200:
              this.user = res.data[0].name;
              break;
          }
        })
        .catch((err) => {
          switch(err.response.status){
            case 400:
              console.log("Error en la aplicación. Consulte a soporte técnico.");
              break;
            case 401:
              console.log("Datos de inicio de sesión erróneos.");
              break;
            case 500:
              console.log("Error en el servidor. Consulte a soporte técnico.");
              break;
          }
        })
      }
    }
  }
</script>