<template>
  <v-app>
    <v-app-bar
      app
      color="secondaryLight"
      light
    >
      <div class="d-flex align-center">
        <v-img
          alt="Invesia Logo"
          class="shrink mr-2"
          contain
          src="@/assets/images/logo.png"
          transition="scale-transition"
          width="150"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://invesia.com"
        target="_blank"
        text
      >
        <span class="mr-2">Invesia.com</span>
        <v-icon>mdi-web</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <NavBar v-if="$route.name != 'Login' && $route.name != 'RecoverPass'" />
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'App',
  components: {
    NavBar
  },
  methods: {
    
  },
};
</script>
<style scoped>
.container{
  padding: 0px;
}
</style>