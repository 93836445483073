import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: "Login - Invesia App"
    }
  },
  {
    path: '/recoverPassword',
    name: 'RecoverPass',
    meta: {
      title: "Recuperar contraseña - Invesia App"
    },
    component: () => import(/* webpackChunkName: "RecoverPass" */ '../views/RecoverPass.vue')
  },
  {
    path: '/tiposdocumento',
    name: 'Tipos de documentos',
    meta: {
      title: "Tipos de documentos - Invesia App"
    },
    component: () => import(/* webpackChunkName: "tipoDocumento" */ '../views/tipoDocumento.vue')
  },
  {
    path: '/maquinaria',
    name: 'Maquinaria',
    meta: {
      title: "Maquinaria - Invesia App"
    },
    component: () => import(/* webpackChunkName: "Maquinaria" */ '../views/Maquinaria.vue')
  },
  {
    path: '/obras',
    name: 'Obras',
    meta: {
      title: "Obras - Invesia App"
    },
    props: true,
    component: () => import(/* webpackChunkName: "Obras" */ '../views/Obras.vue')
  },
  {
    path: '/informes',
    name: 'Informes',
    meta: {
      title: "Informes - Invesia App"
    },
    component: () => import(/* webpackChunkName: "Informes" */ '../views/Informes.vue')
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    meta: {
      title: "Usuarios - Invesia App"
    },
    component: () => import(/* webpackChunkName: "Usuarios" */ '../views/Usuarios.vue')
  },
  {
    path: '/parteobra',
    name: 'ParteObra',
    meta: {
      title: "Parte de obra - Invesia App"
    },
    props: true,
    component: () => import(/* webpackChunkName: "ParteObra" */ '../views/ParteObra.vue')
  },
  {
    path: '/exporta',
    name: 'Exporta',
    meta: {
      title: "Exportación de datos - Invesia App"
    },
    props: true,
    component: () => import(/* webpackChunkName: "Exporta" */ '../views/Exporta.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

router.beforeEach((to, from, next) => {
  if(to.name == 'RecoverPass'){
    next()
  }else{
    if (to.name !== 'Login' && (store.state.auth=='' || store.state.auth==undefined)){
      next({ name: 'Login' })
    }else{
      next()
    }
  }
})


export default router
