<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card v-if="recoverShow == false" class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Inicio de sesión</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field v-on:keyup.enter="login" id="user" prepend-icon="mdi-account-circle" name="login" label="Usuario" type="text" v-model="post.user"></v-text-field>
                  <v-text-field v-on:keyup.enter="login" id="password" prepend-icon="mdi-lock" name="password" label="Contraseña" :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)" :type="value ? 'password' : 'text'" v-model="post.pass"></v-text-field>
                </v-form>
                <p>{{ message }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showRecover" text color="primary">Recupera contraseña</v-btn>
                <v-btn @click="login" color="primary">Acceder</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-if="recoverShow == true" class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Recupera contraseña</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field v-on:keyup.enter="recover" id="recoverUser" prepend-icon="mdi-account-circle" name="recoverUser" label="Usuario" type="text" v-model="putRecover.user"></v-text-field>
                </v-form>
                <p>{{ messageRecover }}</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showRecover" text color="primary">Inicia sesión</v-btn>
                <v-btn @click="recover" color="primary">Recuperar</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
const axios = require("axios");
import {BASE_URL} from '../components/constants.js'
export default {
  name: 'Login',
  data: () => ({
    value: true,
    message: "",
    messageRecover: "",
    recoverShow: false,
    post: {
      user: "",
      pass: ""
    },
    putRecover: {
      user: ""
    },
  }),
  mounted() {
    this.cierraSesion();
  },
  methods: {
    login() {
      var postData = {
        email: this.post.user,
        password: this.post.pass,
      };
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      axios.post(BASE_URL+'/api/auth/loginWeb', postData, axiosConfig)
      .then((res) => {
        switch(res.status){
          case 200:
            this.$store.commit('modifyAuth',res.data.JWT);
            this.$store.commit('modifyUser',res.data.id);
            this.message = "";
            this.$router.push({name: 'Obras'});
            break;
        }
      })
      .catch((err) => {
        switch(err.response.status){
          case 400:
            if(this.post.user!="" && this.post.pass!="")
              this.message = "Error en la aplicación. Consulte a soporte técnico.";
            else
              this.message = "Los campos de inicio de sesión son obligatorios";
            break;
          case 401:
            this.message = "Datos de inicio de sesión erróneos";
            break;
          case 500:
            this.message = "Error en el servidor. Consulte a soporte técnico.";
            break;
        }
      })
    },
    showRecover(){
      if(this.recoverShow){
        this.recoverShow = false
      }else{
        this.recoverShow = true
      }
    },
    recover(){
      var putData = {
        email: this.putRecover.user
      };
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      
      axios.put(BASE_URL+'/api/auth/recoverPass', putData, axiosConfig)
      .then((res) => {
        switch(res.status){
          case 200:
            this.messageRecover = "Se ha enviado un email a su correo para recuperar la contraseña";
            break;
        }
      })
      .catch((err) => {
        switch(err.response.status){
          case 400:
            if(this.putRecover.user!="")
              this.messageRecover = "Error en la aplicación. Consulte a soporte técnico.";
            else
              this.messageRecover = "Introduzca su email para recuperar la contraseña";
            break;
          case 500:
            this.messageRecover = "Error en el servidor. Consulte a soporte técnico.";
            break;
        }
      })
    },
    checkJWT(){
      var postData = {
        email: this.post.user,
        password: this.post.pass,
      };
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.$store.state.auth
        }
      };

      axios.post(BASE_URL+'/api/auth/checkJWT', postData, axiosConfig)
      .then((res) => {
        switch(res.status){
          case 200:
            return 'ok'
            break;
        }
      })
      .catch((err) => { return err.response.status; })
    },
    cierraSesion(){
      this.$store.commit('modifyAuth','');
    }
  }
}
</script>
<style scoped>
main {
  background-image: url("../assets/images/fondoLogin.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}
</style>
