import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: '',
    user: '',
    NewOrEdit: 'new',
    editingReport: '',
    choice: 0,
    documentsTypes: [],
    tools: [],
    mimeTypes : [{extension:".323", value:"text/h323"},
                {extension:".3g2", value:"video/3gpp2"},
                {extension:".3gp", value:"video/3gpp"},
                {extension:".3gp2", value:"video/3gpp2"},
                {extension:".3gpp", value:"video/3gpp"},
                {extension:".7z", value:"application/x-7z-compressed"},
                {extension:".aa", value:"audio/audible"},
                {extension:".AAC", value:"audio/aac"},
                {extension:".aaf", value:"application/octet-stream"},
                {extension:".aax", value:"audio/vnd.audible.aax"},
                {extension:".ac3", value:"audio/ac3"},
                {extension:".aca", value:"application/octet-stream"},
                {extension:".accda", value:"application/msaccess.addin"},
                {extension:".accdb", value:"application/msaccess"},
                {extension:".accdc", value:"application/msaccess.cab"},
                {extension:".accde", value:"application/msaccess"},
                {extension:".accdr", value:"application/msaccess.runtime"},
                {extension:".accdt", value:"application/msaccess"},
                {extension:".accdw", value:"application/msaccess.webapplication"},
                {extension:".accft", value:"application/msaccess.ftemplate"},
                {extension:".acx", value:"application/internet-property-stream"},
                {extension:".AddIn", value:"text/xml"},
                {extension:".ade", value:"application/msaccess"},
                {extension:".adobebridge", value:"application/x-bridge-url"},
                {extension:".adp", value:"application/msaccess"},
                {extension:".ADT", value:"audio/vnd.dlna.adts"},
                {extension:".ADTS", value:"audio/aac"},
                {extension:".afm", value:"application/octet-stream"},
                {extension:".ai", value:"application/postscript"},
                {extension:".aif", value:"audio/aiff"},
                {extension:".aifc", value:"audio/aiff"},
                {extension:".aiff", value:"audio/aiff"},
                {extension:".air", value:"application/vnd.adobe.air-application-installer-package+zip"},
                {extension:".amc", value:"application/mpeg"},
                {extension:".anx", value:"application/annodex"},
                {extension:".apk", value:"application/vnd.android.package-archive"},
                {extension:".application", value:"application/x-ms-application"},
                {extension:".art", value:"image/x-jg"},
                {extension:".asa", value:"application/xml"},
                {extension:".asax", value:"application/xml"},
                {extension:".ascx", value:"application/xml"},
                {extension:".asd", value:"application/octet-stream"},
                {extension:".asf", value:"video/x-ms-asf"},
                {extension:".ashx", value:"application/xml"},
                {extension:".asi", value:"application/octet-stream"},
                {extension:".asm", value:"text/plain"},
                {extension:".asmx", value:"application/xml"},
                {extension:".aspx", value:"application/xml"},
                {extension:".asr", value:"video/x-ms-asf"},
                {extension:".asx", value:"video/x-ms-asf"},
                {extension:".atom", value:"application/atom+xml"},
                {extension:".au", value:"audio/basic"},
                {extension:".avci", value:"image/avci"},
                {extension:".avcs", value:"image/avcs"},
                {extension:".avi", value:"video/x-msvideo"},
                {extension:".avif", value:"image/avif"},
                {extension:".avifs", value:"image/avif-sequence"},
                {extension:".axa", value:"audio/annodex"},
                {extension:".axs", value:"application/olescript"},
                {extension:".axv", value:"video/annodex"},
                {extension:".bas", value:"text/plain"},
                {extension:".bcpio", value:"application/x-bcpio"},
                {extension:".bin", value:"application/octet-stream"},
                {extension:".bmp", value:"image/bmp"},
                {extension:".c", value:"text/plain"},
                {extension:".cab", value:"application/octet-stream"},
                {extension:".caf", value:"audio/x-caf"},
                {extension:".calx", value:"application/vnd.ms-office.calx"},
                {extension:".cat", value:"application/vnd.ms-pki.seccat"},
                {extension:".cc", value:"text/plain"},
                {extension:".cd", value:"text/plain"},
                {extension:".cdda", value:"audio/aiff"},
                {extension:".cdf", value:"application/x-cdf"},
                {extension:".cer", value:"application/x-x509-ca-cert"},
                {extension:".cfg", value:"text/plain"},
                {extension:".chm", value:"application/octet-stream"},
                {extension:".class", value:"application/x-java-applet"},
                {extension:".clp", value:"application/x-msclip"},
                {extension:".cmd", value:"text/plain"},
                {extension:".cmx", value:"image/x-cmx"},
                {extension:".cnf", value:"text/plain"},
                {extension:".cod", value:"image/cis-cod"},
                {extension:".config", value:"application/xml"},
                {extension:".contact", value:"text/x-ms-contact"},
                {extension:".coverage", value:"application/xml"},
                {extension:".cpio", value:"application/x-cpio"},
                {extension:".cpp", value:"text/plain"},
                {extension:".crd", value:"application/x-mscardfile"},
                {extension:".crl", value:"application/pkix-crl"},
                {extension:".crt", value:"application/x-x509-ca-cert"},
                {extension:".cs", value:"text/plain"},
                {extension:".csdproj", value:"text/plain"},
                {extension:".csh", value:"application/x-csh"},
                {extension:".csproj", value:"text/plain"},
                {extension:".css", value:"text/css"},
                {extension:".csv", value:"text/csv"},
                {extension:".cur", value:"application/octet-stream"},
                {extension:".cxx", value:"text/plain"},
                {extension:".dat", value:"application/octet-stream"},
                {extension:".datasource", value:"application/xml"},
                {extension:".dbproj", value:"text/plain"},
                {extension:".dcr", value:"application/x-director"},
                {extension:".def", value:"text/plain"},
                {extension:".deploy", value:"application/octet-stream"},
                {extension:".der", value:"application/x-x509-ca-cert"},
                {extension:".dgml", value:"application/xml"},
                {extension:".dib", value:"image/bmp"},
                {extension:".dif", value:"video/x-dv"},
                {extension:".dir", value:"application/x-director"},
                {extension:".disco", value:"text/xml"},
                {extension:".divx", value:"video/divx"},
                {extension:".dll", value:"application/x-msdownload"},
                {extension:".dll.config", value:"text/xml"},
                {extension:".dlm", value:"text/dlm"},
                {extension:".doc", value:"application/msword"},
                {extension:".docm", value:"application/vnd.ms-word.document.macroEnabled.12"},
                {extension:".docx", value:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"},
                {extension:".dot", value:"application/msword"},
                {extension:".dotm", value:"application/vnd.ms-word.template.macroEnabled.12"},
                {extension:".dotx", value:"application/vnd.openxmlformats-officedocument.wordprocessingml.template"},
                {extension:".dsp", value:"application/octet-stream"},
                {extension:".dsw", value:"text/plain"},
                {extension:".dtd", value:"text/xml"},
                {extension:".dtsConfig", value:"text/xml"},
                {extension:".dv", value:"video/x-dv"},
                {extension:".dvi", value:"application/x-dvi"},
                {extension:".dwf", value:"drawing/x-dwf"},
                {extension:".dwg", value:"application/acad"},
                {extension:".dwp", value:"application/octet-stream"},
                {extension:".dxf", value:"application/x-dxf"},
                {extension:".dxr", value:"application/x-director"},
                {extension:".eml", value:"message/rfc822"},
                {extension:".emf", value:"image/emf"},
                {extension:".emz", value:"application/octet-stream"},
                {extension:".eot", value:"application/vnd.ms-fontobject"},
                {extension:".eps", value:"application/postscript"},
                {extension:".es", value:"application/ecmascript"},
                {extension:".etl", value:"application/etl"},
                {extension:".etx", value:"text/x-setext"},
                {extension:".evy", value:"application/envoy"},
                {extension:".exe", value:"application/vnd.microsoft.portable-executable"},
                {extension:".exe.config", value:"text/xml"},
                {extension:".f4v", value:"video/mp4"},
                {extension:".fdf", value:"application/vnd.fdf"},
                {extension:".fif", value:"application/fractals"},
                {extension:".filters", value:"application/xml"},
                {extension:".fla", value:"application/octet-stream"},
                {extension:".flac", value:"audio/flac"},
                {extension:".flr", value:"x-world/x-vrml"},
                {extension:".flv", value:"video/x-flv"},
                {extension:".fsscript", value:"application/fsharp-script"},
                {extension:".fsx", value:"application/fsharp-script"},
                {extension:".generictest", value:"application/xml"},
                {extension:".gif", value:"image/gif"},
                {extension:".gpx", value:"application/gpx+xml"},
                {extension:".group", value:"text/x-ms-group"},
                {extension:".gsm", value:"audio/x-gsm"},
                {extension:".gtar", value:"application/x-gtar"},
                {extension:".gz", value:"application/x-gzip"},
                {extension:".h", value:"text/plain"},
                {extension:".hdf", value:"application/x-hdf"},
                {extension:".hdml", value:"text/x-hdml"},
                {extension:".heic", value:"image/heic"},
                {extension:".heics", value:"image/heic-sequence"},
                {extension:".heif", value:"image/heif"},
                {extension:".heifs", value:"image/heif-sequence"},
                {extension:".hhc", value:"application/x-oleobject"},
                {extension:".hhk", value:"application/octet-stream"},
                {extension:".hhp", value:"application/octet-stream"},
                {extension:".hlp", value:"application/winhlp"},
                {extension:".hpp", value:"text/plain"},
                {extension:".hqx", value:"application/mac-binhex40"},
                {extension:".hta", value:"application/hta"},
                {extension:".htc", value:"text/x-component"},
                {extension:".htm", value:"text/html"},
                {extension:".html", value:"text/html"},
                {extension:".htt", value:"text/webviewhtml"},
                {extension:".hxa", value:"application/xml"},
                {extension:".hxc", value:"application/xml"},
                {extension:".hxd", value:"application/octet-stream"},
                {extension:".hxe", value:"application/xml"},
                {extension:".hxf", value:"application/xml"},
                {extension:".hxh", value:"application/octet-stream"},
                {extension:".hxi", value:"application/octet-stream"},
                {extension:".hxk", value:"application/xml"},
                {extension:".hxq", value:"application/octet-stream"},
                {extension:".hxr", value:"application/octet-stream"},
                {extension:".hxs", value:"application/octet-stream"},
                {extension:".hxt", value:"text/html"},
                {extension:".hxv", value:"application/xml"},
                {extension:".hxw", value:"application/octet-stream"},
                {extension:".hxx", value:"text/plain"},
                {extension:".i", value:"text/plain"},
                {extension:".ical", value:"text/calendar"},
                {extension:".icalendar", value:"text/calendar"},
                {extension:".ico", value:"image/x-icon"},
                {extension:".ics", value:"text/calendar"},
                {extension:".idl", value:"text/plain"},
                {extension:".ief", value:"image/ief"},
                {extension:".ifb", value:"text/calendar"},
                {extension:".iii", value:"application/x-iphone"},
                {extension:".inc", value:"text/plain"},
                {extension:".inf", value:"application/octet-stream"},
                {extension:".ini", value:"text/plain"},
                {extension:".inl", value:"text/plain"},
                {extension:".ins", value:"application/x-internet-signup"},
                {extension:".ipa", value:"application/x-itunes-ipa"},
                {extension:".ipg", value:"application/x-itunes-ipg"},
                {extension:".ipproj", value:"text/plain"},
                {extension:".ipsw", value:"application/x-itunes-ipsw"},
                {extension:".iqy", value:"text/x-ms-iqy"},
                {extension:".isp", value:"application/x-internet-signup"},
                {extension:".isma", value:"application/octet-stream"},
                {extension:".ismv", value:"application/octet-stream"},
                {extension:".ite", value:"application/x-itunes-ite"},
                {extension:".itlp", value:"application/x-itunes-itlp"},
                {extension:".itms", value:"application/x-itunes-itms"},
                {extension:".itpc", value:"application/x-itunes-itpc"},
                {extension:".IVF", value:"video/x-ivf"},
                {extension:".jar", value:"application/java-archive"},
                {extension:".java", value:"application/octet-stream"},
                {extension:".jck", value:"application/liquidmotion"},
                {extension:".jcz", value:"application/liquidmotion"},
                {extension:".jfif", value:"image/pjpeg"},
                {extension:".jnlp", value:"application/x-java-jnlp-file"},
                {extension:".jpb", value:"application/octet-stream"},
                {extension:".jpg", value:"image/jpg"},
                {extension:".jpeg", value:"image/jpeg"},
                {extension:".js", value:"application/javascript"},
                {extension:".json", value:"application/json"},
                {extension:".jsx", value:"text/jscript"},
                {extension:".jsxbin", value:"text/plain"},
                {extension:".latex", value:"application/x-latex"},
                {extension:".library-ms", value:"application/windows-library+xml"},
                {extension:".lit", value:"application/x-ms-reader"},
                {extension:".loadtest", value:"application/xml"},
                {extension:".lpk", value:"application/octet-stream"},
                {extension:".lsf", value:"video/x-la-asf"},
                {extension:".lst", value:"text/plain"},
                {extension:".lsx", value:"video/x-la-asf"},
                {extension:".lzh", value:"application/octet-stream"},
                {extension:".m13", value:"application/x-msmediaview"},
                {extension:".m14", value:"application/x-msmediaview"},
                {extension:".m1v", value:"video/mpeg"},
                {extension:".m2t", value:"video/vnd.dlna.mpeg-tts"},
                {extension:".m2ts", value:"video/vnd.dlna.mpeg-tts"},
                {extension:".m2v", value:"video/mpeg"},
                {extension:".m3u", value:"audio/x-mpegurl"},
                {extension:".m3u8", value:"audio/x-mpegurl"},
                {extension:".m4a", value:"audio/m4a"},
                {extension:".m4b", value:"audio/m4b"},
                {extension:".m4p", value:"audio/m4p"},
                {extension:".m4r", value:"audio/x-m4r"},
                {extension:".m4v", value:"video/x-m4v"},
                {extension:".mac", value:"image/x-macpaint"},
                {extension:".mak", value:"text/plain"},
                {extension:".man", value:"application/x-troff-man"},
                {extension:".manifest", value:"application/x-ms-manifest"},
                {extension:".map", value:"text/plain"},
                {extension:".master", value:"application/xml"},
                {extension:".mbox", value:"application/mbox"},
                {extension:".mda", value:"application/msaccess"},
                {extension:".mdb", value:"application/x-msaccess"},
                {extension:".mde", value:"application/msaccess"},
                {extension:".mdp", value:"application/octet-stream"},
                {extension:".me", value:"application/x-troff-me"},
                {extension:".mfp", value:"application/x-shockwave-flash"},
                {extension:".mht", value:"message/rfc822"},
                {extension:".mhtml", value:"message/rfc822"},
                {extension:".mid", value:"audio/mid"},
                {extension:".midi", value:"audio/mid"},
                {extension:".mix", value:"application/octet-stream"},
                {extension:".mk", value:"text/plain"},
                {extension:".mk3d", value:"video/x-matroska-3d"},
                {extension:".mka", value:"audio/x-matroska"},
                {extension:".mkv", value:"video/x-matroska"},
                {extension:".mmf", value:"application/x-smaf"},
                {extension:".mno", value:"text/xml"},
                {extension:".mny", value:"application/x-msmoney"},
                {extension:".mod", value:"video/mpeg"},
                {extension:".mov", value:"video/quicktime"},
                {extension:".movie", value:"video/x-sgi-movie"},
                {extension:".mp2", value:"video/mpeg"},
                {extension:".mp2v", value:"video/mpeg"},
                {extension:".mp3", value:"audio/mpeg"},
                {extension:".mp4", value:"video/mp4"},
                {extension:".mp4v", value:"video/mp4"},
                {extension:".mpa", value:"video/mpeg"},
                {extension:".mpe", value:"video/mpeg"},
                {extension:".mpeg", value:"video/mpeg"},
                {extension:".mpf", value:"application/vnd.ms-mediapackage"},
                {extension:".mpg", value:"video/mpeg"},
                {extension:".mpp", value:"application/vnd.ms-project"},
                {extension:".mpv2", value:"video/mpeg"},
                {extension:".mqv", value:"video/quicktime"},
                {extension:".ms", value:"application/x-troff-ms"},
                {extension:".msg", value:"application/vnd.ms-outlook"},
                {extension:".msi", value:"application/octet-stream"},
                {extension:".mso", value:"application/octet-stream"},
                {extension:".mts", value:"video/vnd.dlna.mpeg-tts"},
                {extension:".mtx", value:"application/xml"},
                {extension:".mvb", value:"application/x-msmediaview"},
                {extension:".mvc", value:"application/x-miva-compiled"},
                {extension:".mxf", value:"application/mxf"},
                {extension:".mxp", value:"application/x-mmxp"},
                {extension:".nc", value:"application/x-netcdf"},
                {extension:".nsc", value:"video/x-ms-asf"},
                {extension:".nws", value:"message/rfc822"},
                {extension:".ocx", value:"application/octet-stream"},
                {extension:".oda", value:"application/oda"},
                {extension:".odb", value:"application/vnd.oasis.opendocument.database"},
                {extension:".odc", value:"application/vnd.oasis.opendocument.chart"},
                {extension:".odf", value:"application/vnd.oasis.opendocument.formula"},
                {extension:".odg", value:"application/vnd.oasis.opendocument.graphics"},
                {extension:".odh", value:"text/plain"},
                {extension:".odi", value:"application/vnd.oasis.opendocument.image"},
                {extension:".odl", value:"text/plain"},
                {extension:".odm", value:"application/vnd.oasis.opendocument.text-master"},
                {extension:".odp", value:"application/vnd.oasis.opendocument.presentation"},
                {extension:".ods", value:"application/vnd.oasis.opendocument.spreadsheet"},
                {extension:".odt", value:"application/vnd.oasis.opendocument.text"},
                {extension:".oga", value:"audio/ogg"},
                {extension:".ogg", value:"audio/ogg"},
                {extension:".ogv", value:"video/ogg"},
                {extension:".ogx", value:"application/ogg"},
                {extension:".one", value:"application/onenote"},
                {extension:".onea", value:"application/onenote"},
                {extension:".onepkg", value:"application/onenote"},
                {extension:".onetmp", value:"application/onenote"},
                {extension:".onetoc", value:"application/onenote"},
                {extension:".onetoc2", value:"application/onenote"},
                {extension:".opus", value:"audio/ogg"},
                {extension:".orderedtest", value:"application/xml"},
                {extension:".osdx", value:"application/opensearchdescription+xml"},
                {extension:".otf", value:"application/font-sfnt"},
                {extension:".otg", value:"application/vnd.oasis.opendocument.graphics-template"},
                {extension:".oth", value:"application/vnd.oasis.opendocument.text-web"},
                {extension:".otp", value:"application/vnd.oasis.opendocument.presentation-template"},
                {extension:".ots", value:"application/vnd.oasis.opendocument.spreadsheet-template"},
                {extension:".ott", value:"application/vnd.oasis.opendocument.text-template"},
                {extension:".oxps", value:"application/oxps"},
                {extension:".oxt", value:"application/vnd.openofficeorg.extension"},
                {extension:".p10", value:"application/pkcs10"},
                {extension:".p12", value:"application/x-pkcs12"},
                {extension:".p7b", value:"application/x-pkcs7-certificates"},
                {extension:".p7c", value:"application/pkcs7-mime"},
                {extension:".p7m", value:"application/pkcs7-mime"},
                {extension:".p7r", value:"application/x-pkcs7-certreqresp"},
                {extension:".p7s", value:"application/pkcs7-signature"},
                {extension:".pbm", value:"image/x-portable-bitmap"},
                {extension:".pcast", value:"application/x-podcast"},
                {extension:".pct", value:"image/pict"},
                {extension:".pcx", value:"application/octet-stream"},
                {extension:".pcz", value:"application/octet-stream"},
                {extension:".pdf", value:"application/pdf"},
                {extension:".pfb", value:"application/octet-stream"},
                {extension:".pfm", value:"application/octet-stream"},
                {extension:".pfx", value:"application/x-pkcs12"},
                {extension:".pgm", value:"image/x-portable-graymap"},
                {extension:".pic", value:"image/pict"},
                {extension:".pict", value:"image/pict"},
                {extension:".pkgdef", value:"text/plain"},
                {extension:".pkgundef", value:"text/plain"},
                {extension:".pko", value:"application/vnd.ms-pki.pko"},
                {extension:".pls", value:"audio/scpls"},
                {extension:".pma", value:"application/x-perfmon"},
                {extension:".pmc", value:"application/x-perfmon"},
                {extension:".pml", value:"application/x-perfmon"},
                {extension:".pmr", value:"application/x-perfmon"},
                {extension:".pmw", value:"application/x-perfmon"},
                {extension:".png", value:"image/png"},
                {extension:".pnm", value:"image/x-portable-anymap"},
                {extension:".pnt", value:"image/x-macpaint"},
                {extension:".pntg", value:"image/x-macpaint"},
                {extension:".pnz", value:"image/png"},
                {extension:".pot", value:"application/vnd.ms-powerpoint"},
                {extension:".potm", value:"application/vnd.ms-powerpoint.template.macroEnabled.12"},
                {extension:".potx", value:"application/vnd.openxmlformats-officedocument.presentationml.template"},
                {extension:".ppa", value:"application/vnd.ms-powerpoint"},
                {extension:".ppam", value:"application/vnd.ms-powerpoint.addin.macroEnabled.12"},
                {extension:".ppm", value:"image/x-portable-pixmap"},
                {extension:".pps", value:"application/vnd.ms-powerpoint"},
                {extension:".ppsm", value:"application/vnd.ms-powerpoint.slideshow.macroEnabled.12"},
                {extension:".ppsx", value:"application/vnd.openxmlformats-officedocument.presentationml.slideshow"},
                {extension:".ppt", value:"application/vnd.ms-powerpoint"},
                {extension:".pptm", value:"application/vnd.ms-powerpoint.presentation.macroEnabled.12"},
                {extension:".pptx", value:"application/vnd.openxmlformats-officedocument.presentationml.presentation"},
                {extension:".prf", value:"application/pics-rules"},
                {extension:".prm", value:"application/octet-stream"},
                {extension:".prx", value:"application/octet-stream"},
                {extension:".ps", value:"application/postscript"},
                {extension:".psc1", value:"application/PowerShell"},
                {extension:".psd", value:"application/octet-stream"},
                {extension:".psess", value:"application/xml"},
                {extension:".psm", value:"application/octet-stream"},
                {extension:".psp", value:"application/octet-stream"},
                {extension:".pst", value:"application/vnd.ms-outlook"},
                {extension:".pub", value:"application/x-mspublisher"},
                {extension:".pwz", value:"application/vnd.ms-powerpoint"},
                {extension:".qht", value:"text/x-html-insertion"},
                {extension:".qhtm", value:"text/x-html-insertion"},
                {extension:".qt", value:"video/quicktime"},
                {extension:".qti", value:"image/x-quicktime"},
                {extension:".qtif", value:"image/x-quicktime"},
                {extension:".qtl", value:"application/x-quicktimeplayer"},
                {extension:".qxd", value:"application/octet-stream"},
                {extension:".ra", value:"audio/x-pn-realaudio"},
                {extension:".ram", value:"audio/x-pn-realaudio"},
                {extension:".rar", value:"application/x-rar-compressed"},
                {extension:".ras", value:"image/x-cmu-raster"},
                {extension:".rat", value:"application/rat-file"},
                {extension:".rc", value:"text/plain"},
                {extension:".rc2", value:"text/plain"},
                {extension:".rct", value:"text/plain"},
                {extension:".rdlc", value:"application/xml"},
                {extension:".reg", value:"text/plain"},
                {extension:".resx", value:"application/xml"},
                {extension:".rf", value:"image/vnd.rn-realflash"},
                {extension:".rgb", value:"image/x-rgb"},
                {extension:".rgs", value:"text/plain"},
                {extension:".rm", value:"application/vnd.rn-realmedia"},
                {extension:".rmi", value:"audio/mid"},
                {extension:".rmp", value:"application/vnd.rn-rn_music_package"},
                {extension:".rmvb", value:"application/vnd.rn-realmedia-vbr"},
                {extension:".roff", value:"application/x-troff"},
                {extension:".rpm", value:"audio/x-pn-realaudio-plugin"},
                {extension:".rqy", value:"text/x-ms-rqy"},
                {extension:".rtf", value:"application/rtf"},
                {extension:".rtx", value:"text/richtext"},
                {extension:".rvt", value:"application/octet-stream"},
                {extension:".ruleset", value:"application/xml"},
                {extension:".s", value:"text/plain"},
                {extension:".safariextz", value:"application/x-safari-safariextz"},
                {extension:".scd", value:"application/x-msschedule"},
                {extension:".scr", value:"text/plain"},
                {extension:".sct", value:"text/scriptlet"},
                {extension:".sd2", value:"audio/x-sd2"},
                {extension:".sdp", value:"application/sdp"},
                {extension:".sea", value:"application/octet-stream"},
                {extension:".searchConnector-ms", value:"application/windows-search-connector+xml"},
                {extension:".setpay", value:"application/set-payment-initiation"},
                {extension:".setreg", value:"application/set-registration-initiation"},
                {extension:".settings", value:"application/xml"},
                {extension:".sgimb", value:"application/x-sgimb"},
                {extension:".sgml", value:"text/sgml"},
                {extension:".sh", value:"application/x-sh"},
                {extension:".shar", value:"application/x-shar"},
                {extension:".shtml", value:"text/html"},
                {extension:".sit", value:"application/x-stuffit"},
                {extension:".sitemap", value:"application/xml"},
                {extension:".skin", value:"application/xml"},
                {extension:".skp", value:"application/x-koan"},
                {extension:".sldm", value:"application/vnd.ms-powerpoint.slide.macroEnabled.12"},
                {extension:".sldx", value:"application/vnd.openxmlformats-officedocument.presentationml.slide"},
                {extension:".slk", value:"application/vnd.ms-excel"},
                {extension:".sln", value:"text/plain"},
                {extension:".slupkg-ms", value:"application/x-ms-license"},
                {extension:".smd", value:"audio/x-smd"},
                {extension:".smi", value:"application/octet-stream"},
                {extension:".smx", value:"audio/x-smd"},
                {extension:".smz", value:"audio/x-smd"},
                {extension:".snd", value:"audio/basic"},
                {extension:".snippet", value:"application/xml"},
                {extension:".snp", value:"application/octet-stream"},
                {extension:".sql", value:"application/sql"},
                {extension:".sol", value:"text/plain"},
                {extension:".sor", value:"text/plain"},
                {extension:".spc", value:"application/x-pkcs7-certificates"},
                {extension:".spl", value:"application/futuresplash"},
                {extension:".spx", value:"audio/ogg"},
                {extension:".src", value:"application/x-wais-source"},
                {extension:".srf", value:"text/plain"},
                {extension:".SSISDeploymentManifest", value:"text/xml"},
                {extension:".ssm", value:"application/streamingmedia"},
                {extension:".sst", value:"application/vnd.ms-pki.certstore"},
                {extension:".stl", value:"application/vnd.ms-pki.stl"},
                {extension:".sv4cpio", value:"application/x-sv4cpio"},
                {extension:".sv4crc", value:"application/x-sv4crc"},
                {extension:".svc", value:"application/xml"},
                {extension:".svg", value:"image/svg+xml"},
                {extension:".swf", value:"application/x-shockwave-flash"},
                {extension:".step", value:"application/step"},
                {extension:".stp", value:"application/step"},
                {extension:".t", value:"application/x-troff"},
                {extension:".tar", value:"application/x-tar"},
                {extension:".tcl", value:"application/x-tcl"},
                {extension:".testrunconfig", value:"application/xml"},
                {extension:".testsettings", value:"application/xml"},
                {extension:".tex", value:"application/x-tex"},
                {extension:".texi", value:"application/x-texinfo"},
                {extension:".texinfo", value:"application/x-texinfo"},
                {extension:".tgz", value:"application/x-compressed"},
                {extension:".thmx", value:"application/vnd.ms-officetheme"},
                {extension:".thn", value:"application/octet-stream"},
                {extension:".tif", value:"image/tiff"},
                {extension:".tiff", value:"image/tiff"},
                {extension:".tlh", value:"text/plain"},
                {extension:".tli", value:"text/plain"},
                {extension:".toc", value:"application/octet-stream"},
                {extension:".tr", value:"application/x-troff"},
                {extension:".trm", value:"application/x-msterminal"},
                {extension:".trx", value:"application/xml"},
                {extension:".ts", value:"video/vnd.dlna.mpeg-tts"},
                {extension:".ttf", value:"application/font-sfnt"},
                {extension:".tts", value:"video/vnd.dlna.mpeg-tts"},
                {extension:".txt", value:"text/plain"},
                {extension:".u32", value:"application/octet-stream"},
                {extension:".uls", value:"text/iuls"},
                {extension:".user", value:"text/plain"},
                {extension:".ustar", value:"application/x-ustar"},
                {extension:".vb", value:"text/plain"},
                {extension:".vbdproj", value:"text/plain"},
                {extension:".vbk", value:"video/mpeg"},
                {extension:".vbproj", value:"text/plain"},
                {extension:".vbs", value:"text/vbscript"},
                {extension:".vcf", value:"text/x-vcard"},
                {extension:".vcproj", value:"application/xml"},
                {extension:".vcs", value:"text/plain"},
                {extension:".vcxproj", value:"application/xml"},
                {extension:".vddproj", value:"text/plain"},
                {extension:".vdp", value:"text/plain"},
                {extension:".vdproj", value:"text/plain"},
                {extension:".vdx", value:"application/vnd.ms-visio.viewer"},
                {extension:".vml", value:"text/xml"},
                {extension:".vscontent", value:"application/xml"},
                {extension:".vsct", value:"text/xml"},
                {extension:".vsd", value:"application/vnd.visio"},
                {extension:".vsi", value:"application/ms-vsi"},
                {extension:".vsix", value:"application/vsix"},
                {extension:".vsixlangpack", value:"text/xml"},
                {extension:".vsixmanifest", value:"text/xml"},
                {extension:".vsmdi", value:"application/xml"},
                {extension:".vspscc", value:"text/plain"},
                {extension:".vss", value:"application/vnd.visio"},
                {extension:".vsscc", value:"text/plain"},
                {extension:".vssettings", value:"text/xml"},
                {extension:".vssscc", value:"text/plain"},
                {extension:".vst", value:"application/vnd.visio"},
                {extension:".vstemplate", value:"text/xml"},
                {extension:".vsto", value:"application/x-ms-vsto"},
                {extension:".vsw", value:"application/vnd.visio"},
                {extension:".vsx", value:"application/vnd.visio"},
                {extension:".vtt", value:"text/vtt"},
                {extension:".vtx", value:"application/vnd.visio"},
                {extension:".wasm", value:"application/wasm"},
                {extension:".wav", value:"audio/wav"},
                {extension:".wave", value:"audio/wav"},
                {extension:".wax", value:"audio/x-ms-wax"},
                {extension:".wbk", value:"application/msword"},
                {extension:".wbmp", value:"image/vnd.wap.wbmp"},
                {extension:".wcm", value:"application/vnd.ms-works"},
                {extension:".wdb", value:"application/vnd.ms-works"},
                {extension:".wdp", value:"image/vnd.ms-photo"},
                {extension:".webarchive", value:"application/x-safari-webarchive"},
                {extension:".webm", value:"video/webm"},
                {extension:".webp", value:"image/webp"},
                {extension:".webtest", value:"application/xml"},
                {extension:".wiq", value:"application/xml"},
                {extension:".wiz", value:"application/msword"},
                {extension:".wks", value:"application/vnd.ms-works"},
                {extension:".WLMP", value:"application/wlmoviemaker"},
                {extension:".wlpginstall", value:"application/x-wlpg-detect"},
                {extension:".wlpginstall3", value:"application/x-wlpg3-detect"},
                {extension:".wm", value:"video/x-ms-wm"},
                {extension:".wma", value:"audio/x-ms-wma"},
                {extension:".wmd", value:"application/x-ms-wmd"},
                {extension:".wmf", value:"application/x-msmetafile"},
                {extension:".wml", value:"text/vnd.wap.wml"},
                {extension:".wmlc", value:"application/vnd.wap.wmlc"},
                {extension:".wmls", value:"text/vnd.wap.wmlscript"},
                {extension:".wmlsc", value:"application/vnd.wap.wmlscriptc"},
                {extension:".wmp", value:"video/x-ms-wmp"},
                {extension:".wmv", value:"video/x-ms-wmv"},
                {extension:".wmx", value:"video/x-ms-wmx"},
                {extension:".wmz", value:"application/x-ms-wmz"},
                {extension:".woff", value:"application/font-woff"},
                {extension:".woff2", value:"application/font-woff2"},
                {extension:".wpl", value:"application/vnd.ms-wpl"},
                {extension:".wps", value:"application/vnd.ms-works"},
                {extension:".wri", value:"application/x-mswrite"},
                {extension:".wrl", value:"x-world/x-vrml"},
                {extension:".wrz", value:"x-world/x-vrml"},
                {extension:".wsc", value:"text/scriptlet"},
                {extension:".wsdl", value:"text/xml"},
                {extension:".wvx", value:"video/x-ms-wvx"},
                {extension:".x", value:"application/directx"},
                {extension:".xaf", value:"x-world/x-vrml"},
                {extension:".xaml", value:"application/xaml+xml"},
                {extension:".xap", value:"application/x-silverlight-app"},
                {extension:".xbap", value:"application/x-ms-xbap"},
                {extension:".xbm", value:"image/x-xbitmap"},
                {extension:".xdr", value:"text/plain"},
                {extension:".xht", value:"application/xhtml+xml"},
                {extension:".xhtml", value:"application/xhtml+xml"},
                {extension:".xla", value:"application/vnd.ms-excel"},
                {extension:".xlam", value:"application/vnd.ms-excel.addin.macroEnabled.12"},
                {extension:".xlc", value:"application/vnd.ms-excel"},
                {extension:".xld", value:"application/vnd.ms-excel"},
                {extension:".xlk", value:"application/vnd.ms-excel"},
                {extension:".xll", value:"application/vnd.ms-excel"},
                {extension:".xlm", value:"application/vnd.ms-excel"},
                {extension:".xls", value:"application/vnd.ms-excel"},
                {extension:".xlsb", value:"application/vnd.ms-excel.sheet.binary.macroEnabled.12"},
                {extension:".xlsm", value:"application/vnd.ms-excel.sheet.macroEnabled.12"},
                {extension:".xlsx", value:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
                {extension:".xlt", value:"application/vnd.ms-excel"},
                {extension:".xltm", value:"application/vnd.ms-excel.template.macroEnabled.12"},
                {extension:".xltx", value:"application/vnd.openxmlformats-officedocument.spreadsheetml.template"},
                {extension:".xlw", value:"application/vnd.ms-excel"},
                {extension:".xml", value:"text/xml"},
                {extension:".xmp", value:"application/octet-stream"},
                {extension:".xmta", value:"application/xml"},
                {extension:".xof", value:"x-world/x-vrml"},
                {extension:".XOML", value:"text/plain"},
                {extension:".xpm", value:"image/x-xpixmap"},
                {extension:".xps", value:"application/vnd.ms-xpsdocument"},
                {extension:".xrm-ms", value:"text/xml"},
                {extension:".xsc", value:"application/xml"},
                {extension:".xsd", value:"text/xml"},
                {extension:".xsf", value:"text/xml"},
                {extension:".xsl", value:"text/xml"},
                {extension:".xslt", value:"text/xml"},
                {extension:".xsn", value:"application/octet-stream"},
                {extension:".xss", value:"application/xml"},
                {extension:".xspf", value:"application/xspf+xml"},
                {extension:".xtp", value:"application/octet-stream"},
                {extension:".xwd", value:"image/x-xwindowdump"},
                {extension:".z", value:"application/x-compress"},
                {extension:".zip", value:"application/zip"}]
  },
  plugins: [createPersistedState()],
  getters: {
    getExtensionByMimeType: (state) => (value) => {
      return state.mimeTypes.find(mimeType => mimeType.value === value)
    },
  },
  mutations: {
    modifyAuth(state, newAuth){
      state.auth = newAuth
    },
    modifyUser(state, newUser){
      state.user = newUser
    },
    modifyNewOrEdit(state, newValue){
      state.NewOrEdit = newValue
    },
    modifyEditingReport(state, newValue){
      state.editingReport = newValue
    },
    modifyChoice(state, newChoice){
      state.choice = newChoice
    },
    modifyDocumentsTypes(state, types){
      state.documentsTypes = types
    },
    modifyTools(state, tools){
      state.tools = tools
    }
  },
  actions: {
  },
  modules: {
  }
})
